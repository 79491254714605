<template>
  <div>
    <div class="box">
      <div class="bannerM">
        <img src="@/assets/mobileImg/banner.png" alt="" />
      </div>
      <div class="nav">
        <div
          v-for="(item, index) in navlist"
          :key="index"
          @click="navclick(index)"
        >
          <img :src="item.img" alt="" class="navImg" />
          <p class="navtite">{{ item.title }}</p>
        </div>
      </div>
      <div class="about" id="about">
        <!-- <p class="aboutT">关于我们</p>
        <p class="Atext1">
            专注应急管理，提供线上+线下的整体培训解决方案，让安全 培训更加普及和高效
        </p>
        <p class="Atext2">
            我要安全在线培训平台由湖北尚安思德科技有限公司自主研发设计，为安全应急培训和
            职业技能培训提供高质量的课程、先进的技术平台和完善的服务，用专业的资源和先进的在
            线教育理念，结合移动互联技术，提供“线上+线下”相结合的整体培训解决方案。
        </p> -->
        <div class="aboutbg">
          <p class="aboutbgT">湖北尚安思德</p>
        </div>
        <p class="Atext2">
            我要安全在线培训平台由湖北尚安思德科技有限公司开发，依托尚德安全强大的技术和课程制作团队，独立研发的在线学习平台和网络课件资源，推出了“名师授课+助教答疑”的双师课堂学习模式，助其成为人社部“互联网+职业技能培训计划”全国54家优质线上职业技能培训平台，且多门网络课件收录于“学习强国”职业课堂频道。
        </p>
      </div>
     </div>
     <div class="xinwen" id="news">
        <p class="aboutT">新闻资讯</p>
        <p class="Atext1">第一时间触达，让你实时掌握安全领域最新资讯</p>
        <div class="xlunbo" >
          <el-carousel height="214px" indicator-position="none" arrow="always" >
            <el-carousel-item v-for="(item, i) in imglist" :key="i" >
              <div class="carouselTitle" @click="gootherbanner(item.url)">
                <span
                  style="
                    padding-left: 5px;
                    width: 60%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  "
                  >{{ item.title }}</span
                >
                <div>
                  <span
                    v-for="(v, index) in imglist"
                    :key="index"
                    :class="
                      i == index
                        ? 'swiper-pagination-bullet swiperactive'
                        : 'swiper-pagination-bullet'
                    "
                  ></span>
                </div>
              </div>
              <a
                style="display: block"
                :herf="item.url"
                target="_blank"
                @click="gootherbanner(item.url)"
              >
                <img
                  :src="item.img"
                  alt="加载中"
                  style="width: 100%; height: 100%"
                />
              </a>
            </el-carousel-item>
          </el-carousel>
        </div>
        <div style="padding: 0.2rem; box-sizing: border-box; margin-top: 30px">
          <div
            class="flexs new_item1"
            v-for="(item, index) in newList.slice(0, 3)"
            :key="index"
          >
            <div class="new_item_left_pt">
              <div class="new_item_title" v-html="item.title"></div>
            </div>
            <div class="new_item_right">
              <div v-html="item.date"></div>
            </div>
          </div>
        </div>
        <p class="btngd" @click="newsother">更多资讯 ></p>
      </div>
      <div class="kc" id="curriculum">
        <p class="aboutT">课程优势</p>
        <p class="Atext1">
            课程内容丰富多样，涵盖煤矿及非煤矿山、金属冶炼、烟花爆竹、危险化学品、建筑施工、道路交通、冶金、有色、建材、 机械、轻工、纺织、商贸、烟草等行业
        </p>
        <div class="ke_itemM">
          <img src="@/assets/mobileImg/js.png" alt="加载中" class="ke_itemMT" />
          <div class="keM_ttile">师资雄厚</div>
          <p class="keM_ttile1">
            我们的师资力量专业、雄厚、具有权威性；讲师队伍
            也随着课程开发和升级的过程不断壮大。
          </p>
        </div>
        <div class="kec_item">
          <div
            v-for="(item, i) in keclist"
            :key="i"
            :style="{
              background: 'url(' + item.bg + ') no-repeat',
              width: '28%',
              'background-size': '100%',
            }"
            class="kec_itemM"
          >
            <img :src="item.img" alt="" :class="i==0?'kec_itemimg1':'kec_itemimg'" />
            <p class="kec_itemt">{{ item.title }}</p>
          </div>
        </div>
      </div>
    <div class="pt" id="platform">
      <p class="aboutT">平台功能</p>
      <p class="Atext1">
        基于“互联网+培训”理念推出的移动线上培训产品，定位于安
        全培训和技能提升培训好帮手，专注安全生产和职业技能提升培训领域。
      </p>
      <div class="pt_btn">
        <button
          class="btn1"
          @mouseover="btncmouseover(1)"
          :class="{ btn: btnflag == 1 }"
        >
          <img
            src="@/assets/img/phone1.png"
            alt="加载中"
            class="btn_img"
            v-if="btnflag != 1"
          />

          <img
            src="@/assets/img/phone.png"
            alt="加载中"
            class="btn_img"
            v-else
          />
          <span class="btn_span">学习系统</span>
        </button>
        <button
          class="btn1"
          style="margin-left: 0.4rem"
          @mouseover="btncmouseover(2)"
          :class="{ btn: btnflag == 2 }"
        >
          <img
            src="@/assets/img/pc1.png"
            alt="加载中"
            class="btn_img1"
            v-if="btnflag == 2"
          />

          <img src="@/assets/img/pc.png" alt="加载中" class="btn_img1" v-else />
          <span class="btn_span">考试系统</span>
        </button>
      </div>
      <div class="ptbox" v-show="btnflag == 1">
              <p style="text-align: center;margin-bottom: 20px;"><img src="@/assets/img/img06.png" alt=""/> </p>
              <div class="ptboxc">
                <div v-for="(item, index) in xylist" :key="index" class="ptitem">
                <p class="ptitemt">
                 <span class="pttitle">{{item.index}}</span>
                 <span>{{ item.title }}</span>
                </p>
                <p class="ptitemc">{{ item.text }}</p>
               </div>
              </div>

      </div>
      <div v-show="btnflag == 2">
        <img
          src="@/assets/img/gld.png"
          alt=""
          style="width: 100%; height: 100%"
        />
        <div class="ksxt">
          <div v-for="(item, index) in ptlist" :key="index" class="ptitem">
            <p class="ptitemt">
              <img :src="item.img" alt="" />
              <span>{{ item.title }}</span>
            </p>
            <p class="ptitemc">{{ item.text }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="cp" id="product">
      <p class="aboutT">产品体系</p>
      <div class="cpbox">
        <div v-for="(item, i) in cplist" :key="i" class="cpitem">
          <div class="cpboxbg">
            <p>{{ item.word }}</p>
          </div>
          <div class="cpboxt">{{ item.title }}</div>
          <div class="cpboxc">{{ item.text }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      navlist: [
        {
          title: "关于我们",
          img: require("@/assets/mobileImg/jianjie.png"),
        },
        {
          title: "新闻资讯",
          img: require("@/assets/mobileImg/xinwen.png"),
        },
        {
          title: "课程优势",
          img: require("@/assets/mobileImg/youshi.png"),
        },
        {
          title: "平台功能",
          img: require("@/assets/mobileImg/pingtai.png"),
        },
        {
          title: "联系我们",
          img: require("@/assets/mobileImg/lianx.png"),
        },
      ],
      imglist: [], //新闻轮播
      newList: [],
      keclist: [
        {
          bg: require("@/assets/mobileImg/zhuanye.png"),
          title: "专业严谨",
          img: require("@/assets/mobileImg/sc.png"),
        },
        {
          bg: require("@/assets/mobileImg/zhiliang.png"),
          title: "质量保证",
          img: require("@/assets/mobileImg/aq.png"),
        },
        {
          bg: require("@/assets/mobileImg/kecheng.png"),
          title: "课程形式多样",
          img: require("@/assets/mobileImg/xz.png"),
        },
      ], //课程优势
      btnflag: 1,
      xylist:[
        {
          index:'01',
          title:'学习操作简单',
          text:' 快速注册登录，随时随地访问 '
        },
        {
          index:'02',
          title:'多种认证方式',
          text:'短信认证、人脸识别多种认证方式，防止学员挂机操作'
        },
        {
          index:'03',
          title:'多终端同步',
          text:'PC端/H5页面/手机APP/Pad，满足多样化观看方式'
        },
        {
          index:'04',
          title:'培训证明存档',
          text:'培训证明文档自动生成，一键批量下载，提升教务效率'
        }
      ],
      ptlist: [
        {
          img: require("@/assets/img/gld1.png"),
          title: "支持多种题型",
          text: " 单选、多选、判断、案例分析题，自动化组卷阅卷 ",
        },
        {
          img: require("@/assets/img/gld2.png"),
          title: "多种练习方式",
          text: " 章节练习、模拟考试，精选题库，助力学员轻松取证 ",
        },
        {
          img: require("@/assets/img/gld3.png"),
          title: "错题收藏",
          text: "模拟考试错题实时记录，学员重点复习",
        },
        {
          img: require("@/assets/img/gld4.png"),
          title: "答题统计",
          text: " 实时统计章节练习正确率， 分析考试成绩趋势 ",
        },
      ],
      cplist: [
        {
          word: "学",
          title: "学习体系",
          text: "支持多终端学习，学习操作简单，支持学员教学互动",
        },
        {
          word: "考",
          title: "考试体系",
          text: "支持顺序练习、模拟考试等多种练习模式，支持错题回放",
        },
        {
          word: "管",
          title: "监管体系",
          text: "配备账号管理端培训数据一目了然",
        },
        {
          word: "评",
          title: "评估体系",
          text: "可分区域、分工种等条件进行数据筛选统计，实时查看和掌握学员最新动态",
        },
      ],
    };
  },
  beforeMount() {
    this.newlunbo();
    this.getnew();
  },
  methods: {
    gootherbanner(url){
       window.open(url)
    },
    // 锚点跳转
    navclick(i) {
      let selector;
      switch (i) {
        case 0:
          this.$router.push("/about");
          break;
        case 1:
          selector = "#news";
          break;
        case 2:
          selector = "#curriculum";
          break;
        case 3:
          selector = "#platform";
          break;
        case 4:
          this.$router.push("/contactus");
          break;
        default:
          break;
      }
      if (selector) {
        document.querySelector(selector).scrollIntoView({
          behavior: "smooth",
        });
      }
    },
    //新闻更多咨询
    newsother() {
      window.open("http://yjt.hubei.gov.cn/fbjd/dtyw/styw/", "_blank");
    },
    newlunbo() {
      this.$http.get(this.$api + "/api/index/get_img_contents").then((res) => {
        let reg = /(\/|http)[^>]+\.(jpg|jpeg|png|gif)/g;
        let regs = /(\/|http)[^>]+\.(shtml|htm|html)/g;
        let regss = /<span>([^<]*?)<\/span>/gi;
        let imglist = [];
        for (let index = 0; index < res.data.length; index++) {
          imglist.push({
            img: res.data[index].img.match(reg),
            title: res.data[index].title
              .replace(regss, "$1")
              .replace(/(^\s*)|(\s*$)/g, ""),
            url: res.data[index].url.match(regs),
          });
        }
        console.log("imglist", imglist);
        this.imglist = imglist;
      });
    },
    getnew() {
      this.newList = [];
      this.$http.get(this.$api + "/api/index/get_yw_contents").then((res) => {
        // this.newList = res.data.data.data;
        // this.newList[0].description = this.fontNumber2(
        //   this.newList[0].description
        // );
        this.newList = res.data;
      });
    },
    btncmouseover(num) {
      this.btnflag = num;
    },
  },
};
</script>

<style scoped>
div {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  font-family: Source Han Sans CN;
}
.box {
  padding: 0px 16px;
}
.bannerM {
  width: 100%;
  padding-bottom: 15px;
}
.bannerM img {
  width: 100%;
  height: 100%;
}
.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.navImg {
  width: 40px;
  height: 40px;
}
.navtite {
  text-align: center;
  font-size: .26rem;
  padding-top: 10px;
}
.about {
  width: 100%;
  margin-top: 16px;
}
.aboutT {
  text-align: center;
  font-size: 18px;
  color: #000000;
  font-weight: 600;
  padding: 24px 0;
}
.Atext1 {
  color: #666666;
  font-size: 14px;
  text-align: center;
  line-height: 24px;
  font-family: Source Han Sans CN;
}
.Atext2 {
  padding-top: 22px;
  font-size: 15px;
  line-height: 28px;
  text-indent: 28px;
  color: #333333;
  font-family: MicrosoftYaHei;
}
.aboutbg {
  margin-top: 21px;
  background: url("@/assets/mobileImg/gs-bg.png");
  width: 100%;
  height: 165px;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.aboutbgT {
  font-size: 24px;
  font-family: Source Han Sans CN;
  font-weight: 600;
  color: #ffffff;
}
.xinwen {
  margin-top: 36px;
  background: url("@/assets/mobileImg/bg01.png") no-repeat;
  background-size: 100% 100%;
  padding-left: 16px;
  padding-right: 16px;
}
.xlunbo {
  margin-top: 22px;
}
.new_item_title {
  width: 90%;
  font-size: 16px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
}
.new_item_right {
  text-align: right;
  position: relative;
  top: 0.01rem;
  font-size: 14px;
}
.btngd {
  width: 100px;
  height: 32px;
  text-align: center;
  padding-top: 0.22rem;
  margin: 0 auto;
  font-size: 0.16rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #eb0505;
  cursor: pointer;
  font-size: 14px;
  /* margin-bottom: .6rem; */
}
.ke_itemM {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: 23px;
  width: 100%;
  background-image: url("@/assets/mobileImg/shizi.png");
  background-repeat: no-repeat;
  background-size: 100%;
  padding: .17rem 0 .28px 0;
  box-sizing: border-box;
}
.ke_itemMT {
  width: 33px;
}
.new_item1{
    display: flex;
    height: 50px;
    line-height: 36px;
}
.new_item1:first-child{
    padding:.16rem  0;
}
.keM_ttile {
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  line-height: 36px;
}
.kc{
    padding: 0 16px;
}
.keM_ttile1 {
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  color: white;
  padding: 0 16px;
  text-align: center;
  line-height: 28px;
  font-size: .28rem;
}
.kec_item {
  padding: 16px 0;
  display: flex;
  justify-content: space-between;
}
.kec_itemM {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.kec_itemimg1 {
  width: 35px;
  height: 35px;
  margin-top: 35px;
}
.kec_itemimg {
  width: 32px;
  height: 38px;
  margin-top: 35px;
}
.kec_itemt {
  text-align: center;
  color: #fff;
  font-size: .32rem;
  padding: 0 .15rem;
  margin-top: .26rem;
}
.pt {
  margin-top: 36px;
  background: url("@/assets/mobileImg/bg01.png") no-repeat;
  background-size: 100% 100%;
  padding-left: 19px;
  padding-right: 19px;
}
.pt_btn {
  margin-top: 23px;
  margin-bottom: 23px;
}
.btn1 {
  width: 129px;
  height: 45px;
  border-radius: 22px;
}
.btn_span {
  font-size: 14px;
}
.ptbox{

}
.ptboxc {
  display: flex;
  flex-wrap: wrap;
  justify-content:space-between;
}
.pttitle{
    font-size: 18px;
    padding-right: 5px;
    font-weight: 600;
}
.ksxt {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.ptitem {
  width: 40%;
  box-shadow: 0px 0px 2px 1px rgba(235, 5, 5, 0.1);
  padding: .2rem;
  margin-bottom: 30px;
}
.ptitemt {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 30px;
  font-size: .32rem;
  font-weight: 600;
}

.ptitemt img {
  margin-right: 10px;
}
.ptitemc {
  width: 127px;
  text-align: center;
  margin: 0 auto;
  padding-top: 10px;
  line-height: 24px;
  font-size: 15px;
  color:#444444
}
.cp {

}
.cpbox {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  position: absolute;
  padding: 0 5%;
  width: 90%;
}
.cpboxbg {
  background: url("@/assets/img/dbx.png");
  width: 55px;
  height: 64px;
  background-size: 100% 100%;
  position: relative;
  top: -20%;
  left:calc(50% - 25px);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: #fff;
  font-weight: 600;
}
.cpitem {
  width: 45%;
  height: 210px;
  border: 1px solid #eb0505;
  margin: 32px 0;
  padding-bottom: 25px;
  box-sizing: border-box;
}
.cpboxt {
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  margin-top: -18px;
}
.cpboxc {
  color: #333333;
  font-size: 15px;
  width: 120px;
  margin: 0 auto;
  text-align: center;
  margin-top: 10px;
  line-height: 24px;
  margin-bottom: 10px;
}
</style>
