import Vue from 'vue'
import axios from 'axios'
import App from './App.vue'
import router from './router'
import store from './store'
import $ from 'jquery'
Vue.prototype.$http = axios
Vue.prototype.$api = 'https://new.wyaqpx.com/'
import commonData from './utils/common.js'
Vue.prototype.$commonData = commonData
window.jQuery = $;
window.$ = $;
import AMap from 'vue-amap';
Vue.use(AMap);
// import 'bootstrap/dist/css/bootstrap.min.css'
// import 'bootstrap/dist/js/bootstrap.min.js'
import './assets/css/style.css'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
Vue.config.productionTip = false
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
AMap.initAMapApiLoader({
    // 高德key
    key: '93ee328cc9650ee88ba6a8c112fbca71',
    // 插件集合 （插件按需引入）
    plugin: ['AMap.Geolocation']
})