<template>
  <div class="home">
    <header-model @mddw="mddw" />
    <!-- banner图 -->
    <div class="banner" style="position: relative">
      <el-carousel
        :interval="2000"
        arrow="always"
        height="6rem"
        setActiveItem="setvalue"
        @change="bannerchange"
        class="banner1"
      >
        <el-carousel-item v-for="(item, index) in banList" :key="index">
          <img
            :src="item.pc_image"
            alt="加载中"
            style="width: 100%; height:6rem"
          />
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- 关于我们 -->
        <div class="main1 centent" id="gywm">

        <div class="main1_title">关于我们</div>
        <div class="main1_sm">
            专注应急管理，提供线上+线下的整体培训解决方案，让安全培训更简单、更高效
        </div>
        <div class="flexs">
            <div class="main1_left">
            湖北尚安思德
            <div></div>
            </div>
            <div class="main1_right">
            <p>
                我要安全在线培训平台由湖北尚安思德科技有限公司开发，依托尚德安全强大的技术和课程制作团队，独立研发的在线学习平台和网络课件资源，推出了“名师授课+助教答疑”的双师课堂学习模式，助其成为人社部”互联网+职业技能培训计划”全国54家优质线上职业技能培训平台，且多门网络课件收录于“学习强国”职业课堂频道。
            </p>
            <p style="margin-top: 0.4rem">
                该平台聚焦教学、教务、练习、财务、运营、监督查询、学员管理、人脸识别等功能，培训学员可通过PC端和移动端随时随地进行在线学习和练习，培训机构和监管部门可以通过管理后台实时监测学员的学习进度,为安全生产培训提供了一站式的信息化管理，让培训学员有痕迹可查，确保安全生产在线教育真学真会、真学真考、真学真用。
            </p>
            </div>
        </div>
        </div>

    <!-- 新闻资讯 -->
    <div class="new_max centent">
      <div class="new_main1 centent">
        <div class="main1_title">新闻资讯</div>
        <div class="main1_sm">第一时间触达，让你实时掌握安全领域最新资讯</div>
        <div class="flexs">
          <div class="main2_left">
            <el-carousel height="423px" indicator-position="none">
              <el-carousel-item v-for="(item, i) in imglist" :key="i">
                <div class="carouselTitle"  @click="goother(item.url)">
                  <span v-html="item.title" style="padding-left: 10px"></span>
                  <div>
                    <span
                      v-for="(v, index) in imglist"
                      :key="index"
                      :class="
                        i == index
                          ? 'swiper-pagination-bullet swiperactive'
                          : 'swiper-pagination-bullet'
                      "
                    ></span>
                  </div>
                </div>
                <a
                  style="display: block"
                  :herf="item.url"
                  target="_blank"
                  @click="goother(item.url)"
                >
                  <img
                    :src="item.img"
                    alt="加载中"
                    style="width: 100%; height: 100%"
                  />
                </a>
              </el-carousel-item>
            </el-carousel>
          </div>
          <div class="main2_right" v-if="newList.length > 0">
            <div style="padding: 0.2rem; box-sizing: border-box">
              <div
                class="flexs new_item1"
                v-for="(item, index) in newList.slice(0, 7)"
                :key="index"
              >
                <div class="new_item_left_pt">
                  <div class="new_item_title" v-html="item.title"></div>
                </div>
                <div class="new_item_right">
                  <div v-html="item.date"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="btngd" @click="$commonData.toPage('/newpage')">
          更多资讯>
        </div> -->
        <div class="btngd" @click="newsother">更多资讯 ></div>
      </div>
    </div>
    <!-- 课程优势 -->
    <div class="main1 centent" id="kcys">
      <div class="main1_title">课程优势</div>
      <div class="main1_sm">
        <p>
            课程内容丰富多样，涵盖煤矿及非煤矿山、金属冶炼、烟花爆竹、危险化学品、建筑施工、道路交通、冶金、有色、建材、 机械、轻工、纺织、商贸、烟草等行业
        </p>
      </div>
      <div class="clearfix" style="ke_item_max">
        <div
          class="ke_item fl"
          @mouseover="kcmouseover('keitem1', 1)"
          ref="keitem1"
        >
        <div class="ke_itemimg">
            <img
                src="../assets/img/sz.png"
                alt="加载中"
                :class="{ centesty: kenum != 1 }"
                />
        </div>

          <div class="ke_ttile">师资雄厚</div>
          <p
            v-show="kenum == 1"
            style="
              font-size: 0.16rem;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #ffffff;
              color: white;
            "
          >
            我们的师资力量专业、雄厚、具有权威性；讲师队伍
            也随着课程开发和升级的过程不断壮大。
          </p>
        </div>
        <div class="ke_item fl" ref="keitem2" :class="{ centesty: kenum != 2 }">
          <div class="ke_itemimg"><img
            src="../assets/img/zy.png"
            alt="加载中"
            :class="{ centesty: kenum != 2 }"
          /></div>

          <div class="ke_ttile">专业严谨</div>
          <p
            v-show="kenum == 2"
            style="
              font-size: 0.16rem;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #ffffff;
              color: white;
            "
          >
            我们的师资力量专业、雄厚、具有权威性；讲师队伍
            也随着课程开发和升级的过程不断壮大。
          </p>
        </div>
        <div class="ke_item fl" ref="keitem3" :class="{ centesty: kenum != 3 }">
            <div class="ke_itemimg"> <img
            src="../assets/img/zl.png"
            alt="加载中"
            :class="{ centesty: kenum != 3 }"
          /></div>
          <div class="ke_ttile">质量保证</div>
          <p
            v-show="kenum == 3"
            style="
              font-size: 0.16rem;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #ffffff;
              color: white;
            "
          >
            我们的师资力量专业、雄厚、具有权威性；讲师队伍
            也随着课程开发和升级的过程不断壮大。
          </p>
        </div>
        <div class="ke_item fl" ref="keitem4" :class="{ centesty: kenum != 4 }">
        <div class="ke_itemimg"> <img
            src="../assets/img/kc.png"
            alt="加载中"
            :class="{ centesty: kenum != 4 }"
          />
          </div>
          <div class="ke_ttile">课程形式多样</div>
          <p
            v-show="kenum == 4"
            style="
              font-size: 0.16rem;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #ffffff;
              color: white;
            "
          >
            我们的师资力量专业、雄厚、具有权威性；讲师队伍
            也随着课程开发和升级的过程不断壮大。
          </p>
        </div>
      </div>
      <!-- <div class="btngd1">了解更多></div> -->
    </div>
    <!-- 平台功能 -->
    <div class="new_max1 " id="ptgn">
      <div class="new_main1 centent">
        <div class="main1_title">平台功能</div>
        <div class="pt_btn">
          <button
            class="btn1"
            style="margin-left: 0.4rem"
            @mouseover="btncmouseover(1)"
            :class="{ btn: btnflag == 1 }"
          >
            <img
              src="../assets/img/phone1.png"
              alt="加载中"
              class="btn_img"
              v-if="btnflag != 1"
            />

            <img
              src="../assets/img/phone.png"
              alt="加载中"
              class="btn_img"
              v-else
            />
            <span class="btn_span">学习系统</span>
          </button>
          <button
            class="btn1"
            style="margin-left: 0.4rem"
            @mouseover="btncmouseover(2)"
            :class="{ btn: btnflag == 2 }"
          >
            <img
              src="../assets/img/pc1.png"
              alt="加载中"
              class="btn_img1"
              v-if="btnflag == 2"
            />

            <img
              src="../assets/img/pc.png"
              alt="加载中"
              class="btn_img1"
              v-else
            />
            <span class="btn_span">考试系统</span>
          </button>
        </div>
        <div class="main1_msg">
          基于“互联网+培训”理念推出的移动线上培训产品，定位于安全培训和技能提升培训好帮手，专注安全生产和职业技能提升培训领域。
        </div>
        <div class="flexsG" v-show="btnflag == 1">
          <div class="pt_left">
            <img src="../assets/img/img06.png" alt="加载中" />
          </div>
          <div class="pt_right clearfix">
            <div
              class="flexs fl"
              v-for="(item, index) in studentlist"
              :key="index"
            >
              <div class="pt_right_title">0{{ index + 1 }}</div>
              <div style="padding-left: 0.16rem; flex: 1">
                <div class="pt_right_title">{{ item.title }}</div>
                <div class="pt_item_p">
                  {{ item.text }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--管理端 -->
        <div v-show="btnflag == 2" class="flexs gld">
          <div class="gld_item gld_item_left">
            <div style="margin-bottom: 0.7rem;margin-top:.2rem">
              <div style="margin-bottom: 0.1rem">
                <img
                  src="../assets/img/gld1.png"
                  alt="加载中"
                  class="gld_item_img"
                />
                <span class="gld_item_span">支持多种题型</span>
              </div>
              <div class="gld_item_p">
                单选、多选、判断、案例分析题，自动化组卷阅卷
              </div>
            </div>
            <div>
              <div style="margin-bottom: 0.1rem">
                <img
                  src="../assets/img/gld2.png"
                  alt="加载中"
                  class="gld_item_img"
                />
                <span class="gld_item_span">多种练习方式</span>
              </div>
              <div class="gld_item_p">
                章节练习、模拟考试，精选题库，助力学员轻松取证
              </div>
            </div>
          </div>
          <div style="flex:1;max-width: 550px;">
            <img src="../assets/img/gld.png" alt="加载中" class="gld_img" />
          </div>
          <div class="gld_item gld_item_right">
            <div style="margin-bottom: 0.7rem;margin-top:.2rem">
              <div style="margin-bottom: 0.1rem">
                <img
                  src="../assets/img/gld3.png"
                  alt="加载中"
                  class="gld_item_img"
                />
                <span class="gld_item_span">错题收藏</span>
              </div>
              <div class="gld_item_p">模拟考试错题实时记录，学员重点复习</div>
            </div>
            <div>
              <div style="margin-bottom: 0.1rem">
                <img
                  src="../assets/img/gld2.png"
                  alt="加载中"
                  class="gld_item_img"
                />
                <span class="gld_item_span">答题统计</span>
              </div>
              <div class="gld_item_p">
                实时统计章节练习正确率， 分析考试成绩趋势
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 产品体系 -->
    <div class="main3 centent" id="cptx">
      <div class="main3_title">产品体系</div>
      <ul>
        <li>
          <div class="main3_img">
            <!-- <img src="../assets/img/dbx.png" alt="加载中" /> -->
            <div class="main3_text">学</div>
          </div>
          <div class="main3_item_title">学习体系</div>
          <p>支持多终端学习，学习操作简单，支持学员教学互动</p>
        </li>
        <li>
          <div class="main3_img">
            <!-- <img src="../assets/img/dbx.png" alt="加载中" /> -->
            <div class="main3_text">考</div>
          </div>
          <div class="main3_item_title">考试体系</div>
          <p>支持顺序练习、模拟考试等多种练习模式，支持错题回放</p>
        </li>
        <li>
          <div class="main3_img">
            <!-- <img src="../assets/img/dbx.png" alt="加载中" /> -->
            <div class="main3_text">管</div>
          </div>
          <div class="main3_item_title">监管体系</div>
          <p>配备账号管理端培训数据一目了然</p>
        </li>
        <li>
          <div class="main3_img">
            <!-- <img src="../assets/img/dbx.png" alt="加载中" /> -->
            <div class="main3_text">评</div>
          </div>
          <div class="main3_item_title">评估体系</div>
          <p>可分区域、分工种等条件进行数据筛选统计，实时查看和掌握学员最新动态</p>
        </li>
      </ul>
    </div>
    <!-- 课程展示 -->

    <div class="main4 centent">
      <div class="main4_title">课程展示</div>
      <div class="clearfix" style="width: 100%">
        <div
          v-for="(item, index) in curriculum"
          :key="index"
          class="fl main4_item"
        >
          <div class="media-middle">
            <img src="../assets/img/kc_bg.png" class="media-object" />
            <p class="clasp">初训 <span class="smaller">★</span></p>
            <div class="c-center">
              <p class="type_work">·电工作业·</p>
              <p class="exercise">{{ item }}</p>
              <p class="safe">安全生产培训</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer-model />
    <el-backtop>
      <div
        style="
           {
            height: 100%;
            width: 100%;
            background-color: #f2f5f6;
            box-shadow: 0 0 6px rgba(0, 0, 0, 0.12);
            text-align: center;
            line-height: 40px;
            color: #666;
            font-weight: 900;
          }
        "
      >
        <i class="el-icon-arrow-up"></i>
      </div>
    </el-backtop>
  </div>
</template>

<script>
import headerModel from "@/components/header.vue";
import footerModel from "@/components/footer.vue";
export default {
  name: "HomeView",
  data() {
    return {
      MaxWidth: "",
      btnflag: 1,
      setvalue: 0,
      showFlag: false,
      navnum: 0,
      mlurl: "",
      kenum: 1,
      banList: [],
      newList: [],
      curriculum: [
        "低压电工作业",
        "高压电作业",
        "电力电缆作业",
        "电气试验作业",
        "继电保护作业",
        "熔化焊热切割作业",
        "登高架设作业",
        "高处安装维修拆除",
      ], //推荐课程
      studentlist: [
        {
          title: "学习操作简单",
          text: "快速注册登录，随时随地访问",
        },
        {
          title: "多种认证方式",
          text: "短信认证、人脸识别多种认证方式，防止学员挂机操作",
        },
        {
          title: "多终端同步",
          text: "PC端/H5页面/手机APP/Pad,满足多样化观看方式",
        },
        {
          title: "培训证明存档",
          text: "培训证明文档自动生成，一键批量下载，提升教务效率",
        },
      ],
      imglist: [], //新闻轮播
    };
  },

  components: { headerModel, footerModel },
  created(e) {
    // document.title = "首页";
    if (this.$route.query.data && this.$route.query.data.flagtz) {
      this.mddw(this.$route.query.data.index);
    }
    this.getBanner();
    this.getnew();
    this.newlunbo();
  },
  methods: {
    //新闻轮播跳转
    goother(url) {
      console.log(url);
      window.open(url);
    },
    carousel(i) {},
    //新闻更多咨询
    newsother() {
      window.open("http://yjt.hubei.gov.cn/fbjd/dtyw/styw/", "_blank");
    },
    //新闻轮播图
    newlunbo() {
      this.$http.get(this.$api + "/api/index/get_img_contents").then((res) => {
        let reg = /(\/|http)[^>]+\.(jpg|jpeg|png|gif)/g;
        let regs = /(\/|http)[^>]+\.(shtml|htm|html)/g;
        let imglist = [];
        console.log(res);
        for (let index = 0; index < res.data.length; index++) {
          imglist.push({
            img: res.data[index].img.match(reg),
            title: res.data[index].title,
            url: res.data[index].url.match(regs),
          });
        }
        console.log("imglist", imglist);
        this.imglist = imglist;
      });
    },
    mddw(num) {
      if (num == 1) {
        document.querySelector("#gywm").scrollIntoView({
          behavior: "smooth", // 平滑过渡
          block: "start", // 上边框与视窗顶部平齐。默认值
        });
      }
      if (num == 2) {
        document.querySelector("#kcys").scrollIntoView({
          behavior: "smooth", // 平滑过渡
          block: "start", // 上边框与视窗顶部平齐。默认值
        });
      }
      if (num == 3) {
        document.querySelector("#ptgn").scrollIntoView({
          behavior: "smooth", // 平滑过渡
          block: "start", // 上边框与视窗顶部平齐。默认值
        });
      }
      if (num == 4) {
        document.querySelector("#cptx").scrollIntoView({
          behavior: "smooth", // 平滑过渡
          block: "start", // 上边框与视窗顶部平齐。默认值
        });
      }
    },
    // 超出省略号
    fontNumber2(date) {
      const length = date.length;
      if (length > 80) {
        var str = "";
        str = date.substring(0, 80) + "......";
        return str;
      } else {
        return date;
      }
    },
    // 获取新闻列表页
    getnew() {
      this.newList = [];
      this.$http.get(this.$api + "/api/index/get_yw_contents").then((res) => {
        // this.newList = res.data.data.data;
        // this.newList[0].description = this.fontNumber2(
        //   this.newList[0].description
        // );
        this.newList = res.data;
      });
    },
    // 获取首页banner图数据
    getBanner() {
      this.banList = [];
      this.$http
        .get(this.$api + "/api/news/nav_img_list?type=1")
        .then((res) => {
            console.log(res)
          this.banList = res.data.data;
        });
    },
    bannerchange(e) {
      this.setvalue = e;
    },
    nextImg(num) {
      if (num == 1) {
        this.setvalue--;
        if (this.setvalue < 0) {
          this.setvalue = 2;
        }
      }
      if (num == 2) {
        this.setvalue++;
        if (this.setvalue == 3) {
          this.setvalue = 0;
        }
      }
    },
    btncmouseover(num) {
      this.btnflag = num;
    },
    kcmouseover(str, num) {
      this.$refs["keitem1"].style.width = "40%";
      this.$refs["keitem2"].style.width = "17%";
      this.$refs["keitem3"].style.width = "17%";
      this.$refs["keitem4"].style.width = "17%";
      //   this.$refs[
      //     "keitem1"
      //   ].style.backgroundImage = `url('${this.$api}/image/news1.png')`;
      //   this.$refs[
      //     "keitem2"
      //   ].style.backgroundImage = `url('${this.$api}/image/news2.png')`;
      //   this.$refs[
      //     "keitem3"
      //   ].style.backgroundImage = `url('${this.$api}/image/news3.png')`;
      //   this.$refs[
      //     "keitem4"
      //   ].style.backgroundImage = `url('${this.$api}/image/news4.png')`;
      //   this.$refs[str].style.width = "40%";
      //   this.$refs[
      //     str
      //   ].style.backgroundImage = `url('${this.$api}/image/news${num}-1.png')`;
      this.kenum = num;
    },
  },
};
</script>
<style src="../assets/css/home.css"></style>
<style>
.flexsG{
    display: flex;
}
.el-carousel__arrow--left {
  left: 0 !important;
}
.el-carousel__arrow--right {
  right: 0 !important;
}
.el-carousel__arrow {
  width: 50px !important;
  height: 50px !important;
}
.carouselTitle {
  display: flex;
  position: absolute;
  z-index: 5;
  bottom: 0;
  height: 50px;
  color: #fff;
  width: 100%;
  line-height: 32px;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  background-color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
}
.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  border: 1px solid #fff;
  display: inline-block;
  margin-right: 4px;
}
.swiperactive {
  background-color: #fff;
}
.clasp {
  position: absolute;
  top: 13px;
  width: auto;
  min-width: 45px;
  height: 16px;
  background-color: #ff5b02;
  font-size: 12px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 3px;
}
.media-middle {
  position: relative;
}
.media-object {
  width: 200px;
  /* height: 116px; */
}
.c-center {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: absolute;
  top: 24%;
  word-break: keep-all;
  white-space: nowrap;
  font-size: 24px;
}
.type_work {
  text-align: center;
  transform: scale(0.5);
  line-height: 28px;
  margin-bottom: 2px;
  letter-spacing: 3px;
  font-size: 24px;
}
.exercise {
  text-align: center;
  transform: scale(1);
  line-height: 14px;
  margin-bottom: 2px;
  font-weight: bold;
  padding-right: 9px;
}
.safe {
  text-align: center;
  transform: scale(0.6);
  height: 18px;
  line-height: 36px;
  margin: 0;
  font-weight: bold;
  font-size: 18px;
}
</style>
