<template>
    <div class="footer">
      <div class="footer_one">
        <div class="centent clearfix">
          <div class="fl footer_left">
            <div class="footer_title">友情链接</div>
            <div class="clearfix footer_item">
              <div class="fl">
                <a href="https://www.mem.gov.cn/" target="_blank">中华人民共和国应急管理部</a>
              </div>
              <div class="fl">
                <a href="http://yjt.hubei.gov.cn/" target="_blank">湖北省应急管理厅 </a>
              </div>
              <div class="fl">
                <a href="http://yjglt.henan.gov.cn/" target="_blank">河南省应急管理厅 </a>
              </div>
              <div class="fl">
                <a href="http://yjglj.gz.gov.cn/" target="_blank">广州市应急管理局 </a>
              </div>
              <div class="fl">
                <a href="http://yjgl.gd.gov.cn/" target="_blank">广东省应急管理厅 </a>
              </div>
              <div class="fl">
                <a href="http://yjt.hunan.gov.cn/" target="_blank">湖南省应急管理厅 </a>
              </div>
              <div class="fl">
                <a href="https://yjt.sc.gov.cn/" target="_blank"> 四川省应急管理厅 </a>
              </div>
              <div class="fl">
                <a href="http://yjj.wuhan.gov.cn/" target="_blank">武汉市应急管理局 </a>
              </div>
            </div>
          </div>
          <div class="fl footer_right">
            <div class="footer_title">联系我们</div>
            <div class="footer_item1">
              <div class="">咨询电话：17362908907</div>
              <div class="">联系QQ：3311606879</div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer_two">
        <div class="centent">
          <div style="margin-bottom: 0.1rem">
            Copyright © 2017 湖北尚安思德科技有限公司 All Rights Reserved
          </div>
          <div> <span style="cursor:pointer" @click="yuming()">鄂ICP备16019485号-1</span>  鄂公网安备42010602003859号</div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
     methods: {
        yuming(){
            console.log(1)
            window.open('https://beian.miit.gov.cn/','_blank')
        }
    }
}

</script>

<style  src='../assets/css/home.css'>
</style>
