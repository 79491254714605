import router from '@/router'
// 路由跳转
function toPage(url, data) {
    if (data) {
        router.push({ path: url, query: {...data } });
    } else {
        router.push(url);
    }
}
// 超出字数省略号
function fontNumber(date) {
    const length = date.length;
    if (length > 50) {
        var str = "";
        str = date.substring(0, 50) + "......";
        return str;
    } else {
        return date;
    }
}

export default { toPage, fontNumber }