<template>
    <div class="nav centent clearfix">
      <div class="fl" style="width: 20%">
        <img
          src="../assets/img/logo.png"
          alt=""
          style="width: 40%; vertical-align: middle"
          @click="$commonData.toPage('/')"
        />
      </div>
      <div class="fl" style="width: 60%">
        <ul>

          <li
            @mouseover="navbtn(0)"
            :class="{ listyle: navnum == 0 }"
            @click="$commonData.toPage('/')"
          >
            <span :class="{ nav_span: navnum == 0 }">首页 </span>
          </li>
           <li v-for="(item ,index) in navlist" :key="item.id"
             @mouseover="navbtn(item.id)"  @click="nav(item.id,item)"
            :class="{ listyle: navnum == item.id }"
        >
        <span :class="{ nav_span: navnum == item.id }">{{item.name}} </span>
        </li>
          <!-- <li @mouseover="navbtn(2)" :class="{ listyle: navnum == 2 }">
            <span :class="{ nav_span: navnum == 2 }"  @click="$commonData.toPage('/about')"
              >关于我们</span
            >
          </li>
          <li
            @mouseover="navbtn(3)"
            :class="{ listyle: navnum == 3 }"
            @click="otherurl()"
          >
            <span :class="{ nav_span: navnum == 3 }">新闻资讯</span>
          </li>
          <li @mouseover="navbtn(4)" :class="{ listyle: navnum == 4 }">
            <span :class="{ nav_span: navnum == 4 }" @click="mddw(2)"
              >课程优势</span
            >
          </li>
          <li
            @mouseover="navbtn(5)"
            :class="{ listyle: navnum == 5 }"
            @click="mddw(3)"
          >
            <span :class="{ nav_span: navnum == 5 }">平台功能</span>
          </li>
          <li
            @mouseover="navbtn(6)"
            :class="{ listyle: navnum == 6 }"
            @click="mddw(4)"
          >
            <span :class="{ nav_span: navnum == 6 }">产品体系</span>
          </li>
          <li @mouseover="navbtn(7)" :class="{ listyle: navnum == 7 }"  @click="$commonData.toPage('/contactus')">
            <span :class="{ nav_span: navnum == 7 }">联系我们</span>
          </li> -->
        </ul>
      </div>
      <div v-if="imgsrc" class="fr ewm" @mouseover="over" @mouseleave="leave">微信公众号</div>
      <div class="show" id="show" v-if="showFlag">
        <span class="out"></span>
        <img :src="imgsrc" alt="" style="width:100%;height:90%" />
        <p>扫码微信学习</p>
      </div>
    </div>
</template>

<script>
export default {
  data() {
    return {
      showFlag: false,
      navnum: 0,
      navlist:[],
      imgsrc:''
    };
  },
  beforeMount() {
    this.getlist()
    this.getimg()
  },
  methods: {
    getimg(){
        this.$http.get(this.$api+'/api/index/config_data', {
            headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          }
        }).then(res=>{
            if(res.data.code){
                this.imgsrc=res.data.data.study_ewm
            }
        })
    },
    otherurl(url){
        window.open(url, '_blank')
    },
    nav(index,item){
        switch (index) {
            case 1:
                this.$commonData.toPage('/about')
                break;
            case 2:
                window.open(item.http_url, '_blank')
                break;
            case 3:
                this.mddw(3)
                break;
            case 4:
                this.mddw(2)
                break;
            case 5:
                this.mddw(4)
                break;
            case 6:
                this.$commonData.toPage('/contactus')
                break;
            default:
                break;
        }
    },
    getlist(){
        this.$http.get(this.$api+'/api/news/navigation', {
            headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          }
        }).then(res=>{
            if(res.data.code){
                this.navlist=res.data.data
            }
        })
    },
    navbtn(num) {
      this.navnum = num;
    },
    over() {
      this.showFlag = true;
    },
    leave() {
      this.showFlag = false;
    },
    // 锚点定位
    mddw(num) {
      if (this.$route.path == "/") {
        this.$emit("mddw", num);
      } else {
        this.$commonData.toPage("/", { flagtz: true, index: num });
      }
    },
  },
};
</script>
<style  src='../assets/css/home.css'>
</style>
