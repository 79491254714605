import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import mobileH from '../views/mobile/Home.vue'

Vue.use(VueRouter)

const routesP = [{
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { title: '湖北尚安思德' }
},
// 关于我们
{
    path: '/about',
    name: 'about',
    meta: { title: '关于我们' },
    component: () =>
        import( /* webpackChunkName: "about" */ '../views/AboutView.vue')
},
{
    path: '/newpage',
    name: 'newpage',
    meta: { title: '新闻列表' },
    component: () =>
        import( /* webpackChunkName: "about" */ '../views/newpage.vue')
},
{
    path: '/newsDetail',
    name: 'newsDetail',
    meta: { title: '新闻详情' },
    component: () =>
        import( /* webpackChunkName: "about" */ '../views/newsDetail.vue')
},

{
    path: '/contactus',
    name: 'contactus',
    meta: { title: '联系我们' },
    component: () =>
        import( /* webpackChunkName: "about" */ '../views/contactus.vue')
},

{ path: '*', redirect: "/" },
]
const routesM = [
    {
        path: '/',
        name: 'home',
        component: mobileH,
        meta: { title: '湖北尚安思德' }
    },
    {
        path: '/about',
        name: 'about',
        meta: { title: '关于我们' },
        component: () =>
        import( /* webpackChunkName: "about" */ '../views/mobile/About.vue')
    },
    {
        path: '/contactus',
        name: 'contactus',
        meta: { title: '联系我们' },
        component: () =>
        import( /* webpackChunkName: "about" */ '../views/mobile/contact.vue')
    },
]
var routes = [];
if ((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
    routes = routesM
} else {
    routes = routesP
}
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
router.beforeEach(async (to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title;
    }
    await next()
    if (to.path == "/") {
        let num = to.query.index
        if (num == 1) {
            document.querySelector("#gywm").scrollIntoView({
                behavior: "smooth", // 平滑过渡
                block: "start", // 上边框与视窗顶部平齐。默认值
            });
        }
        if (num == 2) {
            document.querySelector("#kcys").scrollIntoView({
                behavior: "smooth", // 平滑过渡
                block: "start", // 上边框与视窗顶部平齐。默认值
            });
        }
        if (num == 3) {
            document.querySelector("#ptgn").scrollIntoView({
                behavior: "smooth", // 平滑过渡
                block: "start", // 上边框与视窗顶部平齐。默认值
            });
        }
        if (num == 4) {
            document.querySelector("#cptx").scrollIntoView({
                behavior: "smooth", // 平滑过渡
                block: "start", // 上边框与视窗顶部平齐。默认值
            });
        }
    }

})
export default router